import React, { useState } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

import Layout from "../components/Layout";
import PageTitle from "../components/PageTitle";
import SelectActivity from "../components/SelectActivity";
import Button from "../components/Button";

import { useFormContext } from "../context/formContext";
import { useLangContext } from "../context/langContext";

import { device } from "../globalStyles";

import { ACTIVITIES } from "../constants/activities";
import I18N from "../constants/languages/NL/nl_translations";
import Seo from "../components/SEO";

const StyledLayout = styled(Layout)`
  position: relative;
`;

const Container = styled.div`
  padding: var(--gutter);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;

  @media (${device.minTablet}) and (min-height: 890px) {
    padding-top: 80px;
  }

  & > label {
    margin-bottom: 1.6rem;
  }

  & > h2 {
    margin-bottom: 3.2rem;
  }

  form {
    width: 100%;
    @media (${device.minTablet}) {
      max-width: min(50vw, 586px);
    }
    input {
      margin-bottom: 3.2rem;
    }
    button {
      margin: auto;
    }
  }
`;

const Activity = styled(SelectActivity)`
  width: 100%;
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 32px;
    @media (${device.minTablet}) and (min-height: 890px) {
      margin-bottom: 80px;
    }
  }
`;

const ActivitiesPage = () => {
  const [error, setError] = useState("");
  const [selected, setSelected] = useState(
    new Array(ACTIVITIES.length).fill(undefined)
  );

  const { setForm, form } = useFormContext();
  const { lang } = useLangContext();
  const copy = I18N[lang].activities;
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formProps = Object.fromEntries(formData);

    const activities = Object.keys(formProps).join("|");
    setForm((form) => ({
      ...form,
    }));

    if (activities) {
      const xhr = new XMLHttpRequest();
      xhr.open(
          "POST",
          "https://cloud.klm-mail.com/klmnl-email-api?action=dataEnrichment&",
          true
      );
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.send(
          JSON.stringify({
            ...form,
            activities,
          })
      );

      xhr.onload = function (this) {
        let response;
        setLoading(false);
        if (this?.response) {
          try {
            response = JSON.parse(this.response);
          } catch (e) {
            console.error(e);
          }
        }
        if (response?.status === "Error") {
          setError(response?.message);
        } else if (this.status >= 200 && this.status < 300) {
          navigate("/ready");
        } else if (this.statusText) {
          setError(this.statusText);
        }
      };
      xhr.onerror = function (this, error) {
        setLoading(false);
        console.error("this error", this);
        console.error("event error", error);
      }
    } else {
      setError(copy.minimum1);
    }
  };

  return (
    <StyledLayout title={copy.pageTitle} pageName="Activities">
      <Container>
        <PageTitle label="2/3" title={copy.title} subtitle={copy.subtitle} />

        <form onSubmit={handleSubmit}>
          {ACTIVITIES.map((a, i) => (
            <Activity
              key={a.id}
              id={a.id}
              name={a.id}
              label={a[lang]}
              error={i === ACTIVITIES.length - 1 && error}
              defaultChecked={form.activities.includes(a.id)}
              onChange={(e) => {
                setSelected((s) => {
                  const copy = [...s];
                  copy[i] = e.target.checked;
                  return copy;
                });
              }}
            />
          ))}
          <Button
            type="submit"
            disabled={!selected.includes(true) && !form.activities}
          >
            {copy.button}
          </Button>
        </form>
      </Container>
    </StyledLayout>
  );
};

export default ActivitiesPage;

export const Head = () => <Seo />;
